import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { DropdownSubmenu, NavDropdownMenu } from 'react-bootstrap-submenu';
import './style.scss';

const NavBar = () => {
    const triviaSubMenu = (
        <>
            <NavDropdown.Item href='/trivia/pictionary-online/'>Pictionary</NavDropdown.Item>
            <NavDropdown.Item href='/trivia/virtual-water-cooler/'>Watercooler</NavDropdown.Item>
            <NavDropdown.Item href='/trivia/games/custom-quiz/'>Custom quiz</NavDropdown.Item>
            <NavDropdown.Item href='/trivia/games/'>All games</NavDropdown.Item>
        </>
    );
    return (
        <Navbar expand='lg' className='sw-navbar'>
            <Navbar.Brand href='/'>
                <img
                    src={'https://sw-assets.gumlet.io/springworks/sw-logo.svg'}
                    data-src={'https://sw-assets.gumlet.io/springworks/sw-logo.svg'}
                    alt='Springworks'
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className='ml-auto text-center'>
                    <Nav.Link href='/about-us/'>ABOUT US</Nav.Link>

                    <NavDropdownMenu alignLeft={true} renderMenuOnMount={true} title='PRODUCTS' id={'sw.products'}>
                        <DropdownSubmenu href='#' title='Engagement'>
                            <div className='trivia-lg'>
                                <DropdownSubmenu
                                    onToggle={() => {
                                        window.location.href = '/trivia/';
                                    }}
                                    href='/trivia/'
                                    title='Trivia'
                                >
                                    {triviaSubMenu}
                                </DropdownSubmenu>
                            </div>
                            <div className='trivia-sm'>
                                <DropdownSubmenu
                                    href='/trivia/'
                                    title={
                                        <div
                                            role={'button'}
                                            aria-hidden='true'
                                            onClick={() => {
                                                window.location.href = '/trivia/';
                                            }}
                                        >
                                            Trivia
                                        </div>
                                    }
                                >
                                    {triviaSubMenu}
                                </DropdownSubmenu>
                            </div>
                            <NavDropdown.Item href='/engagewith/'>EngageWith</NavDropdown.Item>
                        </DropdownSubmenu>
                        <NavDropdown.Item href='https://www.springverify.com/'>Onboarding</NavDropdown.Item>
                        <DropdownSubmenu href='#' title='Recruitment'>
                            <NavDropdown.Item href='https://www.springhire.info/'>SpringHire</NavDropdown.Item>
                            <NavDropdown.Item href='https://www.springrecruit.com/'>SpringRecruit</NavDropdown.Item>
                        </DropdownSubmenu>
                    </NavDropdownMenu>
                    <NavDropdownMenu alignLeft={true} renderMenuOnMount={true} title='RESOURCE' id={'sw.products'}>
                        <NavDropdown.Item href='https://www.springworks.in/blog/' target='_blank'>
                            Blog
                        </NavDropdown.Item>
                        <NavDropdown.Item href='https://www.springworks.in/the-shape-of-work-podcast/' target='_blank'>
                            The Shape of Work Podcast
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            href='https://www.springworks.in/the-shape-of-work-podcast/community/'
                            target='_blank'
                        >
                            HR Community
                        </NavDropdown.Item>
                        <NavDropdown.Item href='https://www.springworks.in/webinars/' target='_blank'>
                            Webinars
                        </NavDropdown.Item>
                    </NavDropdownMenu>
                    <Nav.Link href='mailto:support@springworks.in'>CONTACT US</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
